<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="products-row">
      <v-col
        offset-md="3"
        md="6"
        sm="12">
        <v-row>
          <v-col cols="12">
            <h2>Check member spending</h2>
          </v-col>
          <v-col
            cols="10"
            class="pr-1">
            <vue-tel-input-vuetify
              v-model="tel"
              label="เบอร์โทรศัพท์"
              default-country="th"
              dense
              outlined
              @country-changed="countryChange($event)"
              @keydown.native.enter="findMember()" />
          </v-col>
          <v-col
            cols="2"
            class="pl-0">
            <v-btn
              outlined
              min-height="40px"
              min-width="48px"
              @click="findMember()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="member">
          <v-col
            cols="6"
            class="py-1">
            <h3>ID</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberId }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Tel</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberTel }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Name</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberName }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Type</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberType }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Spending Total</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberSpending }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>DOB</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberBirthDate }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Email</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberEmail }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-tabs
          v-model="tabState"
          background-color="primary"
          dark>
          <v-tab>ประวัติการสั่งซื้อ</v-tab>
          <v-tab>โค้ดของลูกค้า</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabState">
          <v-tab-item>
            <v-card flat>
              <spending-log-table
                v-model="spendingQuery"
                :loading="spendingsLoading"
                :items="spendingLog" />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <promotions-table
                v-model="promotionsQuery"
                :loading="promotionsLoading"
                :items="promotionCodes" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import PromotionProvider from '@/resources/PromotionProvider'
import SpendingLogTable from '../components/SpendingLogTable.vue'
import PromotionsTable from '../components/PromotionsTable.vue'

const MemberService = new MemberProvider()
const PromotionService = new PromotionProvider()

export default {
  components: {
    SpendingLogTable,
    PromotionsTable
  },
  data () {
    return {
      tabState: null,
      tel: null,
      telInfo: null,
      member: null,
      spendingLog: [],
      promotionCodes: [],
      spendingsLoading: false,
      promotionsLoading: false,
      spendingQuery: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['order.createTime'],
        sortDesc: [true]
      },
      promotionsQuery: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdBy'],
        sortDesc: [true]
      }
    }
  },
  computed: {
    memberName () {
      const firstName = this.member?.firstName || ''
      const lastName = this.member?.lastName || ''

      return `${firstName} ${lastName}`
    },
    memberTel () {
      return this.member?.tel || ''
    },
    memberId () {
      return this.member?.id || ''
    },
    memberSpending () {
      return this.member?.spending?.current || 0
    },
    memberBirthDate () {
      return this.member?.birthDate || ''
    },
    memberEmail () {
      return this.member?.email || ''
    },
    memberType () {
      return this.member?.type || ''
    }
  },
  watch: {
    tel: {
      handler (value) {
        if (value.length === 12 && this.telInfo.dialCode === '66') {
          this.tel = `+66${this.tel.slice(0)}`
        }
      },
      deep: true
    },
    spendingQuery: {
      async handler () {
        this.spendingsLoading = true
        if (this.member) {
          await this.getSpendings()
        }
        this.spendingsLoading = false
      },
      deep: true
    },
    promotionsQuery: {
      async handler () {
        this.promotionsLoading = true
        if (this.member) {
          await this.getPromotions()
        }
        this.promotionsLoading = false
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async findMember () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING MEMBER INFO...'
        })

        const { data: member } = await MemberService.getMemberByTel(this.tel.replaceAll(' ', '').replaceAll('-', ''))
        this.member = member

        if (this.member) {
          await this.getPromotions()
          await this.getSpendings()
        }
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getPromotions () {
      try {
        const { data: promotionCodes } = await PromotionService.getPromotionByMemberPaginate(this.member.id, this.promotionsQuery)
        this.promotionCodes = promotionCodes.results
        this.promotionsQuery.total = promotionCodes.total
        this.promotionsQuery.pages = promotionCodes.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-PROMOTIONS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async getSpendings () {
      try {
        const { data: spendingLog } = await MemberService.getOrdersByMemberId(this.member.id, this.spendingQuery)
        this.spendingLog = spendingLog.results
        this.spendingQuery.total = spendingLog.total
        this.spendingQuery.pages = spendingLog.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-SPENDING-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    countryChange (telInfo) {
      this.telInfo = telInfo
    },
    setTelInfo (tel, telInfo) {
      this.tel = this.setThTel(tel, telInfo)
      this.telInfo = telInfo
    },
    setThTel (tel, telInfo) {
      let result = tel
      if (telInfo.dialCode === '66' && tel.length === 10 && tel[0] === '0') {
        result = `+${telInfo.dialCode}${tel.slice(1)}`
      } else if (telInfo.dialCode === '66' && tel.length === 9 && tel[0] !== '0') {
        result = `+${telInfo.dialCode}${tel}`
      }

      return result
    }
  }
}
</script>
